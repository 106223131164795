<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title m-0">
          <h3 class="card-label m-0">
            {{ $t('MENU.apply_commission') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
      </div>
      <div class="card-body">
        <div class="card-body p-0">
          <div class="form-group row">
            <div class="col-lg-6 mb-5">
              <label>{{ $t('apply_commission.from_date') }}<span class="text-danger">*</span></label>
              <input type="date" v-model="data.from_date" class="form-control"
                     :class="validation && validation.from_date ? 'is-invalid' : ''"
                     :placeholder="$t('commission.from_date')"
              />
              <span v-if="validation && validation.from_date" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.from_date[0] }}
                </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('apply_commission.to_date') }}<span class="text-danger">*</span></label>
              <input type="date" v-model="data.to_date" class="form-control"
                     :class="validation && validation.to_date ? 'is-invalid' : ''"
                     :placeholder="$t('apply_commission.to_date')"
              />
              <span v-if="validation && validation.to_date" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.to_date[0] }}
                </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{$t('apply_commission.agent_name')}}</label>
              <div class="input-group">
                <multiselect
                    :class="validation && validation.agent_id ? 'is-invalid' : ''"
                    v-model="agent"
                    :placeholder="$t('apply_commission.agent_name')"
                    label="name"
                    track-by="id"
                    :options="agents"
                    :multiple="false"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false"
                >
                </multiselect>
                <span v-if="validation && validation.agent_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.agent_id[0] }}
                </span>
              </div>
            </div>
          </div>

        </div>
        <div class="card-footer pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6">
              <button
                  type="reset"
                  class="btn btn-primary mr-2"
                  @click="save()"
              >
                {{ $t('save') }}
              </button>
              <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-apply-commission",
  data() {
    return {
      mainRouteDependency: 'base/dependency',
      mainRoute: 'agents/apply_commissions',
      data: {
        agent_id: this.$route.params.agent_id ? this.$route.params.agent_id : null,
        from_date: null,
        to_date: null,
      },

      isEditing: false,
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      validation: null,
      agents: [],
      agent: null,


    };
  },
   watch: {
    "agent": function (val) {
        if (val) {
            this.data.agent_id = val.id;
        }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'apply-commission.index'});
          })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    update() {
      ApiService.put(this.mainRoute + `/${this.$route.params.id}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'apply-commission.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      ApiService.get(this.mainRoute + `/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.agent_id = response.data.data.agent_id;
        this.data.from_date = response.data.data.from_date;
        this.data.to_date = response.data.data.to_date;

        this.agent = response.data.data.agent;
      });
    },


    getAgent() {
      // if (filter && filter.length >= 3) {,{params: {filter: filter}}
        ApiService.get(`${this.mainRouteDependency}/agents`).then((response) => {
          this.agents = response.data.data;
          if (this.data.agent_id){
            this.agent = response.data.data.find((row)=>row.id == this.data.agent_id);
          }
        });
      // }
    },
  },


  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.apply_commission"),
      route: '/agents/apply-commission'
    }, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    this.getAgent();

    if (this.idEditing) {
        this.getData();
    }
  },
};
</script>


